const TitleBar = ({ title, description = "", children }) => {
    return (
        <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-gray-900 fw-bold flex-column justify-content-center my-0">{title}</h1>	
                    <small className="text-muted">{description}</small>
                </div>
                <div className="d-flex align-items-center gap-2 gap-lg-3">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default TitleBar;