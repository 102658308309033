import { useState } from "react";

const Tooltip = ({ content, children }) => {
    const [showTooltip, setShowTooltip] = useState(false);
  
    const handleMouseEnter = () => {
      setShowTooltip(true);
    };
  
    const handleMouseLeave = () => {
      setShowTooltip(false);
    };
  
    return (
      <div className="custom-tooltip-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
        {showTooltip && <div className="custom-tooltip">{content}</div>}
      </div>
    );
  };
  
  export default Tooltip;