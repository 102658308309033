import Dropdown from "../../../Components/DropDown";

const SingleRecording = () => {
    return (
        <div className="col-md-12 mb-3">
            <div className="card recording-card">
                <div className="p-4">
                    <div className="row">
                        <div className="col-md-3">
                            <img className="recording-thumb" src="/app/thumbnail.jpg" alt="" />
                        </div>
                        <div className="col-md-5">
                            <div className="mb-3">
                                <h2 className="text-gray-900 fw-bold">Zubair Khan and Sven <small className="badge bg-info">by Zubair Khan</small></h2>
                                <small className="text-muted">Tue 16th Jan, 2024</small>
                                <span className="meeting-date"></span>
                                <small className="text-muted">5:30 pm</small>
                                <span className="meeting-date"></span>
                                <small className="text-muted">30 min</small>
                            </div>
                            <ul className="mb-3 pl-10">
                                <li><span className="text-muted">Project progress and price escalation.</span></li>
                                <li><span className="text-muted">Using AI to generate content based on product keywords.</span></li>
                                <li><span className="text-muted">Optimizing SEO for a website using Google Sheets.</span></li>
                                <li><span className="text-muted">Using AI to generate content based on product keywords.</span></li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3 text-right d-flex">
                                <button className="btn light-bg btn-sm mr10 flex-grow-1"><i className="fa-light fa-circle-info"></i> Details</button>
                                <button className="btn info-btn btn-sm mr10"><i className="fa-light fa-video"></i> Highlights</button>
                                <Dropdown />
                            </div>
                            <h5 className="text-gray-900 fw-bold">Keywords</h5>
                            <div>
                                <span className="badge bg-light mr5">Nuance</span>
                                <span className="badge bg-light mr5">OpenAI</span>
                                <span className="badge bg-light mr5">SEO</span>
                                <span className="badge bg-light mr5">Keywords Generation</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleRecording;