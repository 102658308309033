import FullRow from "../../../Components/FullRow";
import Tooltip from "../../../Components/Tooltip";

const TranscriptMessage = ({ message, showHighlights = false }) => {
    return (
        <FullRow className='mb-3'>
            <div className="row align-center">
                <div className="col-1">
                    {/* <img src="/app/person1.png" className="rec-avatar" alt="" /> */}
                    <img src={`/app/${message.type === 'client' ? 'person2.png' : 'person1.png'}`} className="rec-avatar" alt="" />
                </div>
                <div className="col-5">
                    <p className="mb-0"><strong>{ message.name }</strong></p>
                    <small>{ message.time }</small>
                </div>
                <div className="col-6 text-right">
                    <Tooltip content={'Add Note'}><button className="btn"><i class="fa-light fa-message-plus"></i></button></Tooltip>
                    <Tooltip content={'Highlight'}>
                        <button className="btn"><i class="fa-light fa-brush"></i></button>
                    </Tooltip>
                </div>
            </div>
            <div className={`row mt-3 ${(message.highlight && showHighlights) ? 'rec-highlight' : 'rec-simple'}`}>
                <span>{ message.message }</span>
            </div>
        </FullRow>
    )
}

export default TranscriptMessage;