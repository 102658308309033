import './styles/recordingCheck.css';

const RecordingCheck = ({ id, onRecord, onChange }) => {

    return (
        <>
            <input type="checkbox" className='meeting-check' checked={onRecord} onChange={onChange} id={id} />
                <label htmlFor={id} className="switch">
                {onRecord ? <i className="fa-light fa-microphone"></i> : <i className="fa-light fa-microphone-slash"></i>}
            </label>
        </>
    );
}

export default RecordingCheck;