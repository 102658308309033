const FullRow = ({ children, className }) => {
    return (
        <div className={`row ${className}`}>
            <div className="col-12">
                {children}
            </div>
        </div>
    )
}

export default FullRow;