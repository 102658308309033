import { Link, useLocation } from "react-router-dom";

const SidebarMenuItem = ({ icon, text, link = "/" }) => {
    const location = useLocation();
    const isActive = location.pathname.includes(link);
    return (
        <Link to={link} className={`menu-item ${isActive ? 'active' : ''}`}>
            <span className="menu-icon">
                <i className={`${isActive ? 'fa-duotone' : 'fa-light'} ${icon}`}></i>
            </span>
            <span className="menu-title">{ text }</span>
        </Link>
    );
}

export default SidebarMenuItem;