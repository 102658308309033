import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';

import { MediaPlayer, MediaProvider } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';

const VideoPlayer = ({ title = "", videoSrc = "", posterSrc = "" }) => {
    return (
        <MediaPlayer muted autoplay title={title} src={videoSrc} style={{ borderRadius: 10 }}>
            <MediaProvider />
            <DefaultVideoLayout thumbnails={posterSrc} icons={defaultLayoutIcons} />
        </MediaPlayer>
    )
}

export default VideoPlayer;