const AppHeader = () => {
    return (
        <div className="app-header" id="kt_app_header" style={{ backgroundColor: 'white' }}>
            <div className="app-container container-fluid d-flex align-items-stretch justify-content-between" id="kt_app_header_container">
                
                <div className="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2" title="Show sidebar menu">
                    <div className="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle">
                        <i className="ki-duotone ki-abstract-14 fs-2 fs-md-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                        </i>
                    </div>
                </div>
                
                <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <a href="/" className="d-lg-none">
                        <img alt="Logo" src="/vite.svg" className="h-30px" />
                    </a>
                </div>

                <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">
                    <div className="app-header-menu app-header-mobile-drawer align-items-stretch">
                        <div className="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0" id="kt_app_header_menu">
                            <div className="menu-item here show menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                                <span className="menu-link">
                                    <span className="menu-title">Dashboards</span>
                                    <span className="menu-arrow d-lg-none"></span>
                                </span>
                            </div>
                            <div className="menu-item menu-lg-down-accordion me-0 me-lg-2">
                                <span className="menu-link">
                                    <span className="menu-title">Pages</span>
                                    <span className="menu-arrow d-lg-none"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="app-navbar flex-shrink-0">
                        <div className="app-navbar-item align-items-stretch ms-1 ms-md-4">
                            <div className="header-search d-flex align-items-stretch" id="kt_header_search">
                                <div className="d-flex align-items-center" data-kt-search-element="toggle" id="kt_header_search_toggle">
                                    <div className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px">
                                        <i className="fa-duotone fa-magnifying-glass"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="app-navbar-item ms-1 ms-md-4" id="kt_header_user_menu_toggle">
                            <div className="cursor-pointer symbol symbol-35px">
                                <img src="/assets/images/default.png" className="rounded-3" alt="user" />
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    )
}

export default AppHeader;