import { useState } from "react";
import FullRow from "../../Components/FullRow";
import TitleBar from "../../Components/User/TitleBar";
import VideoPlayer from "../../Components/player/VideoPlayer";
import AppContainer from "./Includes/AppContainer";
import TranscriptMessage from "./Includes/TranscriptMessage";
// JSONs
import transcript from '../../Examples/transcript.json';
import transcriptData from '../../Examples/keywords.json';
import Card from "../../Components/Card";

const RecordingDetails = () => {

    const [transcription, setTranscription] = useState(transcript);
    const [data, setData] = useState(transcriptData);
    const [showHighlights, setShowHighlights] = useState(false);
    const [activeTab, setActiveTab] = useState('info');
    const [activeTab2, setActiveTab2] = useState('transcription');

    return (
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
                <TitleBar title={"Zubair Khan and Sven de Meyere"} description="Conversation on an AI tool for Google Meet, Zoom and Microsoft Teams.">
                    <button className="btn info-btn btn-sm"><i className="fa-light fa-arrow-up-from-bracket"></i> Upload</button>
                </TitleBar>
                <AppContainer>
                    <div className="row g-5 gx-xl-10 mb-5 mb-xl-10">
                        <div className="col-md-6">
                            <FullRow>
                                <VideoPlayer videoSrc="/app/video.mp4" posterSrc="/app/thumbnail.webp" />
                            </FullRow>
                            <FullRow>
                                <div className="mt-3 d-flex">
                                    <button className={`btn flex-grow-1 ${activeTab === 'info' ? 'info-btn' : ''}`} onClick={() => setActiveTab('info')}><i className="fa-light fa-video"></i> Meeting Info</button>
                                    <button className={`btn flex-grow-1 ${activeTab === 'keywords' ? 'info-btn' : ''}`} onClick={() => setActiveTab('keywords')}><i className="fa-light fa-timer"></i> Keywords</button>
                                    <button className={`btn flex-grow-1 ${activeTab === 'key_points' ? 'info-btn' : ''}`} onClick={() => setActiveTab('key_points')}><i className="fa-light fa-comment"></i> Key Points</button>
                                </div>
                            </FullRow>
                            {/* <hr /> */}
                            <FullRow className="mt-5">
                                {activeTab === 'info' && <Card>
                                    <FullRow>
                                        <h3 className="mb-3">{ data.title } Something</h3>
                                        <p className="text-muted"><i className="fa-thin fa-calendar mr5"></i> Saturday 20 January, 2024 9:00 AM</p>
                                        <p className="text-muted"><i className="fa-thin fa-clock mr5"></i> 28 mins</p>
                                        <p className="text-muted"><i className="fa-thin fa-address-card mr5"></i> Owner: Zubair Khan</p>
                                        <p className="text-muted"><i className="fa-thin fa-users mr5"></i> Shared with Sven de Meyere</p>
                                    </FullRow>
                                </Card>}
                                {activeTab === 'keywords' && <Card>
                                    <FullRow>
                                        <h3>Keywords</h3>
                                        <div className="mt-1">
                                            {data.keywords.map(keyword => <span className="badge bg-light mr5">{ keyword }</span>)}
                                        </div>
                                    </FullRow>
                                </Card>}
                                {activeTab === 'key_points' && <Card>
                                    <FullRow>
                                        <h3>Key Points</h3>
                                        <div className="mt-1">
                                            <ul>
                                            {data.key_points.map(keyword => <li>{ keyword }</li>)}
                                            </ul>
                                        </div>
                                    </FullRow>
                                </Card>}
                                
                            </FullRow>
                        </div>
                        <div className="col-md-6">
                            <FullRow>
                                <div className="mb-3 d-flex">
                                    <button className={`btn flex-grow-1 ${activeTab2 === 'transcription' ? 'info-btn' : ''}`} onClick={() => setActiveTab2('transcription')}><i className="fa-light fa-list"></i> Transcription</button>
                                    <button className={`btn flex-grow-1 ${activeTab2 === 'summary' ? 'info-btn' : ''}`} onClick={() => setActiveTab2('summary')}><i className="fa-thin fa-file-lines"></i> Summary</button>
                                    {/* <button className={`btn flex-grow-1 ${activeTab2 === 'highlights' ? 'info-btn' : ''}`} onClick={() => setActiveTab2('highlights')}><i className="fa-light fa-list-ol"></i> Highlights</button> */}
                                    <button className={`btn flex-grow-1 ${activeTab2 === 'notes' ? 'info-btn' : ''}`} onClick={() => setActiveTab2('notes')}><i className="fa-light fa-clipboard-list-check"></i> Notes</button>
                                </div>
                            </FullRow>
                            {activeTab2 === 'transcription' && <FullRow>
                                <Card>
                                    <div className="row align-center mb-3">
                                        <div className="row">
                                            <div className="col-6">
                                                <h3>Transcription</h3>
                                            </div>
                                            <div className="col-6 text-right">
                                                <button onClick={() => setShowHighlights(!showHighlights)} className="btn info-btn btn-sm">{showHighlights ? 'Hide' : 'Show'} Highlights</button>
                                            </div>
                                        </div>
                                        <FullRow className={'mt-2'}>
                                            <input className="form-control form-control-solid form-control-sm" placeholder="Search for transcription" />
                                        </FullRow>
                                    </div>
                                    <div className='rec-box'>
                                        {transcription.map((message, index) => <TranscriptMessage showHighlights={showHighlights} key={index} message={message} />)}
                                    </div>
                                </Card>
                            </FullRow>}
                            {activeTab2 === 'summary' && <FullRow>
                                <Card>
                                    <div className="row align-center mb-3">
                                        <FullRow>
                                            <h3>Summary</h3>
                                        </FullRow>
                                        <FullRow className={'mt-2'}>
                                            <p>{ data.summary }</p>
                                        </FullRow>
                                    </div>
                                </Card>
                            </FullRow>}
                            {activeTab2 === 'notes' && <FullRow>
                                <Card>
                                    <div className="row align-center mb-3">
                                        <FullRow>
                                            <h3>Create Notes</h3>
                                        </FullRow>
                                        <div className="row">
                                            <div className="col-8">
                                                <input placeholder="Write your note" className="form-control form-control-solid form-control-sm" />
                                            </div>
                                            <div className="col-4">
                                                <button className="btn info-btn btn-sm w-100"><i class="fa-light fa-plus"></i> Add Note</button>
                                            </div>
                                        </div>
                                        <div className={`row mt-3 rec-simple`}>
                                            <div className="col-12">
                                                <span>Ensure the Chatbot can handle multiple user queries simultaneously.</span>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </FullRow>}
                        </div>
                    </div>
                </AppContainer>
            </div>
        </div>
    );
}

export default RecordingDetails;