const Home = () => {
    return (
        <>
            <div className="hero-5 section-space-sm-bottom">
                <div className="container">
                    <div className="row gy-4 align-items-center justify-content-center">
                        <div className="col-lg-7 text-lg-start text-center">
                        <img src="/main/assets/img/hero-5-title-top.webp" alt="topelimage" className="img-fluid fadeIn_bottom" />
                        <h2 className="hero-5-title mb-0 clr-primary-10 fw-extrabold mt-2 animate-text-from-right">Conversation Intelligence Made Simple</h2>
                        <p className="clr-primary-10 fw-bold mt-4 ff-1 max-text-50 animate-text-from-right">Nuance automates note-taking, record-keeping, and insight-capture so you can spend your time where it matters most — coaching your team and closing deals.</p>
                        <div
                            className="d-flex flex-wrap align-items-center gap-6 mt-xl-10 mt-lg-8 mt-6 justify-content-lg-start justify-content-center fadeIn_bottom">
                            <a href="/" target="_blank"
                            className="link d-inline-block py-3 px-7 bg-grad-4 rounded-1 fs-14 fw-bold text-center clr-white ff-1"> Try
                            for Free </a>
                            <a href="/" target="_blank"
                            className="link d-inline-block py-3 px-7 rounded-1 fs-14 fw-bold text-center clr-primary-10 :clr-neutral-100 :bg-primary-10 border border-primary-10 ff-1">
                            Booke a Demo </a>
                        </div>
                        </div>
                        <div className="col-md-10 col-lg-5">
                        <div className="position-relative">
                            <div className="row">
                            <div className="col-6">
                                <img src="/main/assets/img/hero-5-right-3.webp" alt="" className="img-fluid mt-6 fadein_bottom_29" />
                                <img src="/main/assets/img/hero-5-right-4.webp" alt="" className="img-fluid mt-6 fadein_bottom_29" />
                            </div>
                            <div className="col-6">
                                <img src="/main/assets/img/hero-5-right-1.webp" alt="" className="img-fluid fadein_bottom_29" />
                                <img src="/main/assets/img/hero-5-right-2.webp" alt="" className="img-fluid mt-6 fadein_bottom_29" />
                            </div>
                            </div>
                            <img src="/main/assets/img/hero-5-right-arrow1.webp" alt=""
                            className="img-fluid position-absolute hero-5__right hero-5__right-arrow1 fadein_bottom_29" />
                            <img src="/main/assets/img/hero-5-right-arrow2.webp" alt=""
                            className="img-fluid position-absolute hero-5__right hero-5__right-arrow2 fadein_bottom_29" />
                        </div>
                        </div>
                    </div>
                    <div className="row gy-4 section-space-top">
                        <div className="col-sm-6 col-lg-4">
                        <div className="p-5 p-sm-6 bg-white rounded-3 fadein_bottom_30">
                            <div className="d-flex flex-wrap align-items-center gap-4">
                            <span className="w-11 h-11 rounded-circle bg-primary-90 d-grid place-content-center clr-primary-key fs-20">
                                <i className="bi bi-apple"></i>
                            </span>
                            <h6 className="fs-18 mb-0">Already converted</h6>
                            </div>
                            <h4 className="mt-6 mt-xxl-7 h5 fw-extrabold">76% Client's use</h4>
                            <p className="mb-0 mt-0 mt-lg-3 mt-xxl-4">AI-powered translation tools can quickly translate content</p>
                        </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                        <div className="p-5 p-sm-6 bg-white rounded-3 fadein_bottom_30">
                            <div className="d-flex flex-wrap align-items-center gap-4">
                            <span className="w-11 h-11 rounded-circle bg-primary-90 d-grid place-content-center clr-primary-key fs-20">
                                <i className="bi bi-hypnotize"></i>
                            </span>
                            <h6 className="fs-18 mb-0">Already converted</h6>
                            </div>
                            <h4 className="mt-6 mt-xxl-7 h5 fw-extrabold">06 HRS</h4>
                            <p className="mb-0 mt-0 mt-lg-3 mt-xxl-4">AI-powered translation tools can quickly translate content</p>
                        </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                        <div className="p-5 p-sm-6 bg-white rounded-3 fadein_bottom_30">
                            <div className="d-flex flex-wrap align-items-center gap-4">
                            <span className="w-11 h-11 rounded-circle bg-primary-90 d-grid place-content-center clr-primary-key fs-20">
                                <i className="bi bi-apple"></i>
                            </span>
                            <h6 className="fs-18 mb-0">Already converted</h6>
                            </div>
                            <h4 className="mt-6 mt-xxl-7 h5 fw-extrabold">20000 Files</h4>
                            <p className="mb-0 mt-0 mt-lg-3 mt-xxl-4">AI-powered translation tools can quickly translate content</p>
                        </div>
                        </div>
                    </div>
                    <div className="row mt-17">
                        <div className="col-12">
                        <div className="bg-white-grad-4 py-6 px-8 text-center fadeIn_bottom">
                            <h5 className="h6 fw-bold mb-0">15,000+ clients trust WriteBot AI <a href="#0" className="clr-grad-7">Meet Your
                                Customer</a> <i className="bi bi-arrow-right"></i></h5>
                        </div>
                        </div>
                    </div>
                </div>
                <img src="/main/assets/img/hero-5-shade-top.webp" alt="" className="img-fluid hero-5__shape hero-5__shape-top" />
                <img src="/main/assets/img/hero-5-shade-left.webp" alt="" className="img-fluid hero-5__shape hero-5__shape-left" />
                <img src="/main/assets/img/hero-5-shade-left-2.webp" alt="" className="img-fluid hero-5__shape hero-5__shape-left-2" />
                <img src="/main/assets/img/hero-5-shade-circle.webp" alt="" className="img-fluid hero-5__shape hero-5__shape-circle" />
                <img src="/main/assets/img/hero-5-shade-right.webp" alt="" className="img-fluid hero-5__shape hero-5__shape-right" />
                <img src="/main/assets/img/hero-5-top-digit.png" alt="" className="img-fluid hero-5__shape hero-5__shape-digit" />
            </div>
            <section className="section-space-sm-top section-space-sm-bottom voice-upload-section position-relative">
                <div className="section-space-sm-bottom">
                    <div className="container">
                        <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="position-relative text-center">
                            <h3 className="fw-extrabold animate-line-3d">Separate Vocals From Music With <span className="clr-grad-7">One
                                Click With Demo</span></h3>
                            <p className="animate-text-from-bottom">Media.io Online Vocal Remover can separate all mingled-up and blended
                                vocals and instrumental tracks from a song. This allows you to use the extracted.</p>
                            <img src="/main/assets/img/home-5-lightning-fill.png" alt="" className="img-fluid section-title-obj-img" />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xxl-8 col-xl-10">
                        <div className="bg-primary-90 p-2 rounded-3 fadeIn_bottom">
                            <ul className="nav bg-white pill-tabs rounded-3 justify-content-between py-1 px-2" id="pills-tab"
                            role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active rounded-3 px-3 fw-medium" id="pills-vocal-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-vocal" type="button" role="tab" aria-controls="pills-vocal"
                                aria-selected="true">Vocal Remover</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link rounded-3 px-3 fw-medium" id="pills-music-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-music" type="button" role="tab" aria-controls="pills-music"
                                aria-selected="false">Music Generator</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link rounded-3 px-3 fw-medium" id="pills-video-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-video" type="button" role="tab" aria-controls="pills-video"
                                aria-selected="false">Video to audio</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link rounded-3 px-3 fw-medium" id="pills-noise-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-noise" type="button" role="tab" aria-controls="pills-noise"
                                aria-selected="false">Noise Reducer</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link rounded-3 px-3 fw-medium" id="pills-voice-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-voice" type="button" role="tab" aria-controls="pills-voice"
                                aria-selected="false">Voice Changer</button>
                            </li>
                            </ul>
                        </div>
                        <div className="tab-content voice-upload-tab-content  position-relative text-center fadeIn_bottom"
                            id="pills-tabContent">
                            <img src="/main/assets/img/voice-upload-tab-bg.webp" alt="" className="img-fluid voice-upload-tab-bg" />
                            <div className="tab-pane fade show active" id="pills-vocal" role="tabpanel" aria-labelledby="pills-vocal-tab"
                            tabindex="0">
                            <img src="/main/assets/img/voice-upload-tab-1.webp" alt="" className="img-fluid" />
                            </div>
                            <div className="tab-pane fade" id="pills-music" role="tabpanel" aria-labelledby="pills-music-tab" tabindex="0">
                            <img src="/main/assets/img/music-generator.png" alt="" className="img-fluid" />
                            </div>
                            <div className="tab-pane fade" id="pills-video" role="tabpanel" aria-labelledby="pills-video-tab" tabindex="0">
                            <img src="/main/assets/img/video-to-audio.png" alt="" className="img-fluid" />
                            </div>
                            <div className="tab-pane fade" id="pills-noise" role="tabpanel" aria-labelledby="pills-noise-tab" tabindex="0">
                            <img src="/main/assets/img/noise-reducer.png" alt="" className="img-fluid" />
                            </div>
                            <div className="tab-pane fade" id="pills-voice" role="tabpanel" aria-labelledby="pills-voice-tab" tabindex="0">
                            <img src="/main/assets/img/voice-changer.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <img src="/main/assets/img/home-5-header-top-el.webp" alt="" className="img-fluid section-top-bg-shape" />
                <img src="/main/assets/img/home-5-right-shape.png" alt="" className="img-fluid section-right-bg-shape" />
            </section>
            <section className="section-space-sm-top section-space-sm-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div
                            className="why-choose-wrapper bg-black section-space-top section-space-bottom rounded-5 position-relative overflow-hidden fadeIn_bottom">
                            <img src="/main/assets/img/why-choose-top-digit.png" alt=""
                            className="img-fluid why-choose-wrapper-el why-choose-wrapper-el-digit" />
                            <img src="/main/assets/img/why-choose-right-top.webp" alt=""
                            className="img-fluid why-choose-wrapper-el why-choose-wrapper-el-top" />
                            <img src="/main/assets/img/why-choose-right-bottom.webp" alt=""
                            className="img-fluid why-choose-wrapper-el why-choose-wrapper-el-bottom" />
                            <div className="section-space-xsm-bottom">
                            <h3 className="text-white fw-extrabold max-text-24 animate-text-from-right"> Why Choose Writebot <img
                                src="/main/assets/img/why-choose-sound-icon.png" alt="" className="img-fluid fadeIn_bottom" />
                                <span className="clr-grad-7 d-inline-block">Online Vocal Remover?</span>
                            </h3>
                            <p className="max-text-60 clr-neutral-87 animate-text-from-right"> Media.io Online Vocal Remover can separate
                                all mingled-up and blended vocals and instrumental tracks from a song. This allows you to use the
                                extracted. </p>
                            </div>
                            <ul
                            className="nav bg-neutral-key pill-tabs pill-tabs-style-two rounded-3 justify-content-between py-1 px-2 d-inline-flex gap-3 gap-lg-4 gap-xl-5 gap-xxl-7 fadeIn_bottom"
                            id="pills-tab-choose" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active rounded-3 py-3 px-7 fw-medium fs-14" id="pills-upload-tab"
                                data-bs-toggle="pill" data-bs-target="#pills-upload" type="button" role="tab"
                                aria-controls="pills-upload" aria-selected="true">
                                <i className="bi bi-cloud-upload"></i> Upload File </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link rounded-3 py-3 px-7 fw-medium fs-14" id="pills-team-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-team" type="button" role="tab" aria-controls="pills-team"
                                aria-selected="false">
                                <i className="bi bi-person"></i> Teams </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link rounded-3 py-3 px-7 fw-medium fs-14" id="pills-project-tab"
                                data-bs-toggle="pill" data-bs-target="#pills-project" type="button" role="tab"
                                aria-controls="pills-project" aria-selected="false">
                                <i className="bi bi-gear"></i> Projects </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link rounded-3 py-3 px-7 fw-medium fs-14" id="pills-connection-tab"
                                data-bs-toggle="pill" data-bs-target="#pills-connection" type="button" role="tab"
                                aria-controls="pills-connection" aria-selected="false">
                                <i className="bi bi-folder-check"></i> Connections </button>
                            </li>
                            </ul>
                            <div className="tab-content section-space-sm-top" id="pills-choose-tabContent">
                            <div className="tab-pane fade show active" id="pills-upload" role="tabpanel"
                                aria-labelledby="pills-upload-tab" tabindex="0">
                                <div className="row gy-4 justify-content-between align-items-center">
                                <div className="col-lg-5">
                                    <h4 className="clr-white h5 max-text-20 animate-line-3d"> Easy to upload the source music file </h4>
                                    <p className="clr-neutral-80 my-6 animate-text-from-right"> WriteBot AI is a versatile product demo
                                    software crafted to fuel the success of innovative and progressive sales, product, and growth
                                    teams. </p>
                                    <ul className="list gap-3">
                                    <li className="clr-neutral-70 d-flex align-items-center gap-2 fadeIn_bottom">
                                        <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                        <span>Industry-Leading Support & Success</span>
                                    </li>
                                    <li className="clr-neutral-70 d-flex align-items-center gap-2 fadeIn_bottom">
                                        <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                        <span>Scalable Solutions for your Growth</span>
                                    </li>
                                    <li className="clr-neutral-70 d-flex align-items-center gap-2 fadeIn_bottom">
                                        <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                        <span>Unlock Possibilities with Advanced Analytics</span>
                                    </li>
                                    </ul>
                                    <div className="fadeIn_bottom">
                                    <a href="/" target="_blank"
                                        className="link d-inline-flex gap-2 mt-12 py-3 px-7 bg-grad-4 rounded-1 fs-14 fw-bold text-center clr-white ff-1">
                                        Get started for free <i className="bi bi-arrow-right"></i></a>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <img src="/main/assets/img/why-choose-av-1.webp" alt="" className="img-fluid fadeIn_bottom" />
                                </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-team" role="tabpanel" aria-labelledby="pills-team-tab" tabindex="0">
                                <div className="row gy-4 justify-content-between align-items-center">
                                <div className="col-lg-5">
                                    <h4 className="clr-white h5 max-text-20">Easy to upload the source music file</h4>
                                    <p className="clr-neutral-80 my-6"> WriteBot AI is a versatile product demo software crafted to fuel the
                                    success of innovative and progressive sales, product, and growth teams. </p>
                                    <ul className="list gap-3">
                                    <li className="clr-neutral-70 d-flex align-items-center gap-2 fadeIn_bottom">
                                        <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                        <span>Industry-Leading Support & Success</span>
                                    </li>
                                    <li className="clr-neutral-70 d-flex align-items-center gap-2 fadeIn_bottom">
                                        <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                        <span>Scalable Solutions for your Growth</span>
                                    </li>
                                    <li className="clr-neutral-70 d-flex align-items-center gap-2 fadeIn_bottom">
                                        <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                        <span>Unlock Possibilities with Advanced Analytics</span>
                                    </li>
                                    </ul>
                                    <div className="fadeIn_bottom">
                                    <a href="/"
                                        className="link d-inline-flex gap-2 mt-12 py-3 px-7 bg-grad-4 rounded-1 fs-14 fw-bold text-center clr-white ff-1">
                                        Get started for free <i className="bi bi-arrow-right"></i></a>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <img src="/main/assets/img/why-choose-av-3.png" alt="" className="img-fluid" />
                                </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-project" role="tabpanel" aria-labelledby="pills-project-tab"
                                tabindex="0">
                                <div className="row gy-4 justify-content-between align-items-center">
                                <div className="col-lg-5">
                                    <h4 className="clr-white h5 max-text-20">Easy to upload the source music file</h4>
                                    <p className="clr-neutral-80 my-6">WriteBot AI is a versatile product demo software crafted to fuel the
                                    success of innovative and progressive sales, product, and growth teams.</p>
                                    <ul className="list gap-3">
                                    <li className="clr-neutral-70 d-flex align-items-center gap-2">
                                        <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                        <span>Industry-Leading Support & Success</span>
                                    </li>
                                    <li className="clr-neutral-70 d-flex align-items-center gap-2">
                                        <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                        <span>Scalable Solutions for your Growth</span>
                                    </li>
                                    <li className="clr-neutral-70 d-flex align-items-center gap-2">
                                        <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                        <span>Unlock Possibilities with Advanced Analytics</span>
                                    </li>
                                    </ul>
                                    <a href="/"
                                    className="link d-inline-flex gap-2 mt-12 py-3 px-7 bg-grad-4 rounded-1 fs-14 fw-bold text-center clr-white ff-1">
                                    Get started for free <i className="bi bi-arrow-right"></i></a>
                                </div>
                                <div className="col-lg-6">
                                    <img src="/main/assets/img/why-choose-av-3.png" alt="" className="img-fluid" />
                                </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-connection" role="tabpanel" aria-labelledby="pills-connection-tab"
                                tabindex="0">
                                <div className="row gy-4 justify-content-between align-items-center">
                                <div className="col-lg-5">
                                    <h4 className="clr-white h5 max-text-20">Easy to upload the source music file</h4>
                                    <p className="clr-neutral-80 my-6">WriteBot AI is a versatile product demo software crafted to fuel the
                                    success of innovative and progressive sales, product, and growth teams.</p>
                                    <ul className="list gap-3">
                                    <li className="clr-neutral-70 d-flex align-items-center gap-2">
                                        <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                        <span>Industry-Leading Support & Success</span>
                                    </li>
                                    <li className="clr-neutral-70 d-flex align-items-center gap-2">
                                        <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                        <span>Scalable Solutions for your Growth</span>
                                    </li>
                                    <li className="clr-neutral-70 d-flex align-items-center gap-2">
                                        <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                        <span>Unlock Possibilities with Advanced Analytics</span>
                                    </li>
                                    </ul>
                                    <a href="/"
                                    className="link d-inline-flex gap-2 mt-12 py-3 px-7 bg-grad-4 rounded-1 fs-14 fw-bold text-center clr-white ff-1">
                                    Get started for free <i className="bi bi-arrow-right"></i></a>
                                </div>
                                <div className="col-lg-6">
                                    <img src="/main/assets/img/why-choose-av-1.webp" alt="" className="img-fluid" />
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="how-work-section section-space-sm-top section-space-sm-bottom position-relative">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                        <div className="position-relative text-center">
                            <h3 className="fw-extrabold animate-line-3d">How to Separate and Remove Vocals <span className="clr-grad-7">from a
                                Song Online?</span></h3>
                            <p className="max-text-60 mx-auto animate-text-from-bottom">Media.io Online Vocal Remover can separate all
                            mingled-up and blended vocals and instrumental tracks from a song. This allows you to use the extracted.
                            </p>
                            <img src="/main/assets/img/home-5-music-note.webp" alt=""
                            className="img-fluid section-title-obj-img fadeIn_bottom" />
                        </div>
                        </div>
                    </div>
                </div>
                <div className="section-space-sm-top">
                    <div className="container">
                        <div className="how-work-wrapper">
                        <div className="how-work-single-row">
                            <div className="thumb fadeIn_bottom">
                            <img src="/main/assets/img/how-work-1.webp" alt="" className="img-fluid" />
                            </div>
                            <div className="content">
                            <img src="/main/assets/img/how-work-music-wave.webp" alt="" className="img-fluid mb-3 fadeIn_bottom" />
                            <h4 className="animate-line-3d">Upload the Source Music File</h4>
                            <p className="my-6 animate-text-from-right">Wanna share your data with your team mates? Well you might.
                                Fortunately formcarry supports that, invite your clients or team members to your form in seconds, then
                                inspect it together. There are no team limits to join</p>
                            <ul className="list gap-3">
                                <li
                                className="d-flex align-items-center gap-2 justify-content-center justify-content-lg-start fadeIn_bottom">
                                <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                <span>Industry-Leading Support &amp; Success</span>
                                </li>
                                <li
                                className="d-flex align-items-center gap-2 justify-content-center justify-content-lg-start fadeIn_bottom">
                                <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                <span>Scalable Solutions for your Growth</span>
                                </li>
                                <li
                                className="d-flex align-items-center gap-2 justify-content-center justify-content-lg-start fadeIn_bottom">
                                <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                <span>Unlock Possibilities with Advanced Analytics</span>
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div className="how-work-single-row">
                            <div className="thumb fadeIn_bottom">
                            <img src="/main/assets/img/how-work-2.webp" alt="" className="img-fluid" />
                            </div>
                            <div className="content">
                            <img src="/main/assets/img/how-work-music-wave.webp" alt="" className="img-fluid mb-3 fadeIn_bottom" />
                            <h4 className="animate-line-3d">Auto Processing & Extracting</h4>
                            <p className="my-6 animate-text-from-right">Wanna share your data with your team mates? Well you might.
                                Fortunately formcarry supports that, invite your clients or team members to your form in seconds, then
                                inspect it together. There are no team limits to join</p>
                            <ul className="list gap-3">
                                <li
                                className="d-flex align-items-center gap-2 justify-content-center justify-content-lg-start fadeIn_bottom">
                                <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                <span>Industry-Leading Support &amp; Success</span>
                                </li>
                                <li
                                className="d-flex align-items-center gap-2 justify-content-center justify-content-lg-start fadeIn_bottom">
                                <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                <span>Scalable Solutions for your Growth</span>
                                </li>
                                <li
                                className="d-flex align-items-center gap-2 justify-content-center justify-content-lg-start fadeIn_bottom">
                                <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                <span>Unlock Possibilities with Advanced Analytics</span>
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div className="how-work-single-row">
                            <div className="thumb fadeIn_bottom">
                            <img src="/main/assets/img/how-work-3.webp" alt="" className="img-fluid" />
                            </div>
                            <div className="content">
                            <img src="/main/assets/img/how-work-music-wave.webp" alt="" className="img-fluid mb-3 fadeIn_bottom" />
                            <h4 className="animate-line-3d">Preview & Download Tracks</h4>
                            <p className="my-6 animate-text-from-right">Wanna share your data with your team mates? Well you might.
                                Fortunately formcarry supports that, invite your clients or team members to your form in seconds, then
                                inspect it together. There are no team limits to join</p>
                            <ul className="list gap-3">
                                <li
                                className="d-flex align-items-center gap-2 justify-content-center justify-content-lg-start fadeIn_bottom">
                                <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                <span>Industry-Leading Support &amp; Success</span>
                                </li>
                                <li
                                className="d-flex align-items-center gap-2 justify-content-center justify-content-lg-start fadeIn_bottom">
                                <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                <span>Scalable Solutions for your Growth</span>
                                </li>
                                <li
                                className="d-flex align-items-center gap-2 justify-content-center justify-content-lg-start fadeIn_bottom">
                                <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                <span>Unlock Possibilities with Advanced Analytics</span>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <img src="/main/assets/img/home-5-header-top-el-2.png" alt="" className="img-fluid section-top-bg-shape" />
                <img src="/main/assets/img/home-5-left-shape.png" alt="" className="img-fluid how-work-shape how-work-shape-left" />
                <img src="/main/assets/img/home-5-right-shape.png" alt="" className="img-fluid how-work-shape how-work-shape-right" />
            </section>
            <section className="newletter-section-2 section-space-sm-top section-space-sm-bottom">
                <div className="container">
                    <div
                        className="newsletter-wrappper rounded-4 overflow-hidden section-space-top section-space-sm-bottom px-xl-10 px-6 fadeIn_bottom">
                        <div className="row justify-content-center">
                        <div className="col-xxl-6 col-xl-8 col-lg-10">
                            <h3 className="fw-extrabold text-center clr-white mb-0 animate-line-3d">Get <span className="clr-grad-7">Started
                                Now</span></h3>
                            <p className="clr-neutral-90 text-center mt-6 mb-7 animate-text-from-bottom">Automate campaign management.
                            Automate growth.</p>
                            <form
                            className="d-flex flex-sm-row flex-column gap-sm-0 gap-3 p-3 bg-primary-10 rounded-3 fadeIn_bottom newsletter-1--alt border border-primary-20">
                            <input type="email"
                                className="form-control newsletter-1__input bg-transparent text-sm-start text-center clr-neutral-90"
                                name="#" placeholder="join the community" />
                            <button type="button"
                                className="newsletter-1__btn d-inline-flex justify-content-center gap-2 py-3 px-5 rounded-3 fw-bold clr-white bg-grad-4">Subscribe
                                <i className="bi bi-arrow-up-right"></i></button>
                            </form>
                            <p className="text-center clr-primary-90 fs-14 mt-7 animate-text-from-bottom">✓ 14-day free trial ✓ No credit
                            card required ✓ Cancel anytime</p>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pricing-section-4 section-space-sm-top section-space-sm-bottom position-relative">
                <div className="container">
                    <div
                        className="pricing-section-4-wrapper rounded-4 surface-bright section-space-top section-space-bottom position-relative overflow-hidden px-5 px-lg-10 px-xxl-20">
                        <div className="row gy-4 align-items-center justify-content-between">
                        <div className="col-lg-6">
                            <h3 className="fw-extrabold mb-0 animate-line-3d">Pick a Plan Start <span className="clr-grad-7">Creating Price
                                Table.</span></h3>
                        </div>
                        <div className="col-lg-6">
                            <p className="mb-0 animate-text-from-right">Media.io Online Vocal Remover can separate all mingled-up and
                            blended vocals and instrumental tracks from a song. This allows you to use the extracted.</p>
                        </div>
                        </div>
                        <div className="mt-10">
                        <div className="row justify-content-center">
                            <div className="col-xxl-11">
                            <div className="row gy-4">
                                <div className="col-lg-4">
                                <div className="bg-white rounded-5 p-4 p-md-10 fadein_bottom_31">
                                    <h6 className="mb-3"> Free Plan </h6>
                                    <span className="d-block mb-3 clr-neutral-variant-50 fs-14"> Start with 3,000 word limit </span>
                                    <h4 className="monthly-price mb-10 clr-primary-10 fw-extrabold"> $0/mo </h4>
                                    <h4 className="yearly-price mb-10 clr-primary-10 fw-extrabold"> $0/y </h4>
                                    <a href="/"
                                    className="link d-inline-flex justify-content-center align-items-center gap-2 mb-10 py-2 px-4 border border-primary-10 bg-primary-10 rounded-3 fs-14 fw-bold text-center clr-white :bg-grad-4 :border-opacity-0">
                                    <span className="d-block"> Try For Free </span>
                                    <span className="d-block fs-10">
                                        <i className="bi bi-arrow-right"></i>
                                    </span>
                                    </a>
                                    <ul className="list gap-4">
                                    <li>
                                        <div className="d-flex gap-3">
                                        <span
                                            className="flex-shrink-0 d-grid place-content-center w-5 h-5 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-10">
                                            <i className="bi bi-check2"></i>
                                        </span>
                                        <span className="d-block fs-14 clr-primary-10 fw-medium"> 25 Free Messages </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex gap-3">
                                        <span
                                            className="flex-shrink-0 d-grid place-content-center w-5 h-5 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-10">
                                            <i className="bi bi-check2"></i>
                                        </span>
                                        <span className="d-block fs-14 clr-primary-10 fw-medium"> 1 Chatbot </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex gap-3">
                                        <span
                                            className="flex-shrink-0 d-grid place-content-center w-5 h-5 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-10">
                                            <i className="bi bi-check2"></i>
                                        </span>
                                        <span className="d-block fs-14 clr-primary-10 fw-medium"> Basic Models </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex gap-3">
                                        <span
                                            className="flex-shrink-0 d-grid place-content-center w-5 h-5 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-10">
                                            <i className="bi bi-check2"></i>
                                        </span>
                                        <span className="d-block fs-14 clr-primary-10 fw-medium"> 100 Captured Contacts </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex gap-3">
                                        <span
                                            className="flex-shrink-0 d-grid place-content-center w-5 h-5 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-10">
                                            <i className="bi bi-check2"></i>
                                        </span>
                                        <span className="d-block fs-14 clr-primary-10 fw-medium"> Basic Support </span>
                                        </div>
                                    </li>
                                    </ul>
                                </div>
                                </div>
                                <div className="col-lg-4">
                                <div className="bg-white rounded-5 p-4 p-md-10 fadein_bottom_31 position-relative z-1 overflow-hidden">
                                    <span className="pricing-label bg-secondary-40"> Popular Plan </span>
                                    <h6 className="mb-3">Popular Plan</h6>
                                    <span className="d-block mb-3 clr-neutral-variant-50 fs-14"> Start with 3,000 word limit </span>
                                    <h4 className="monthly-price mb-10 clr-primary-10 fw-extrabold"> $29/mo </h4>
                                    <h4 className="yearly-price mb-10 clr-primary-10 fw-extrabold"> $0/y </h4>
                                    <a href="/" target="_blank"
                                    className="link d-inline-flex justify-content-center align-items-center gap-2 mb-10 py-2 px-4 border border-primary-10 :bg-primary-10 rounded-3 fs-14 fw-bold text-center clr-white bg-grad-4 border-opacity-0">
                                    <span className="d-block"> Try For Free </span>
                                    <span className="d-block fs-10">
                                        <i className="bi bi-arrow-right"></i>
                                    </span>
                                    </a>
                                    <ul className="list gap-4">
                                    <li>
                                        <div className="d-flex gap-3">
                                        <span
                                            className="flex-shrink-0 d-grid place-content-center w-5 h-5 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-10">
                                            <i className="bi bi-check2"></i>
                                        </span>
                                        <span className="d-block fs-14 clr-primary-10 fw-medium"> 1,500 Messages included </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex gap-3">
                                        <span
                                            className="flex-shrink-0 d-grid place-content-center w-5 h-5 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-10">
                                            <i className="bi bi-check2"></i>
                                        </span>
                                        <span className="d-block fs-14 clr-primary-10 fw-medium"> 2 Chatbots </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex gap-3">
                                        <span
                                            className="flex-shrink-0 d-grid place-content-center w-5 h-5 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-10">
                                            <i className="bi bi-check2"></i>
                                        </span>
                                        <span className="d-block fs-14 clr-primary-10 fw-medium"> Advanced Model (GPT-4)</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex gap-3">
                                        <span
                                            className="flex-shrink-0 d-grid place-content-center w-5 h-5 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-10">
                                            <i className="bi bi-check2"></i>
                                        </span>
                                        <span className="d-block fs-14 clr-primary-10 fw-medium"> 200 Files and Webpages </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex gap-3">
                                        <span
                                            className="flex-shrink-0 d-grid place-content-center w-5 h-5 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-10">
                                            <i className="bi bi-check2"></i>
                                        </span>
                                        <span className="d-block fs-14 clr-primary-10 fw-medium"> 1,000 Captured Contacts</span>
                                        </div>
                                    </li>
                                    </ul>
                                </div>
                                </div>
                                <div className="col-lg-4">
                                <div className="bg-white rounded-5 p-4 p-md-10 fadein_bottom_31">
                                    <h6 className="mb-3"> Premium Plan </h6>
                                    <span className="d-block mb-3 clr-neutral-variant-50 fs-14"> Start with 3,000 word limit </span>
                                    <h4 className="monthly-price mb-10 clr-primary-10 fw-extrabold"> $29/mo </h4>
                                    <h4 className="yearly-price mb-10 clr-primary-10 fw-extrabold"> $0/y </h4>
                                    <a href="/" target="_blank"
                                    className="link d-inline-flex justify-content-center align-items-center gap-2 mb-10 py-2 px-4 border border-primary-10 bg-primary-10 rounded-3 fs-14 fw-bold text-center clr-white :bg-grad-4 :border-opacity-0">
                                    <span className="d-block"> Try For Free </span>
                                    <span className="d-block fs-10">
                                        <i className="bi bi-arrow-right"></i>
                                    </span>
                                    </a>
                                    <ul className="list gap-4">
                                    <li>
                                        <div className="d-flex gap-3">
                                        <span
                                            className="flex-shrink-0 d-grid place-content-center w-5 h-5 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-10">
                                            <i className="bi bi-check2"></i>
                                        </span>
                                        <span className="d-block fs-14 clr-primary-10 fw-medium"> 5,000 Messages included </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex gap-3">
                                        <span
                                            className="flex-shrink-0 d-grid place-content-center w-5 h-5 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-10">
                                            <i className="bi bi-check2"></i>
                                        </span>
                                        <span className="d-block fs-14 clr-primary-10 fw-medium"> 10 Chatbots </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex gap-3">
                                        <span
                                            className="flex-shrink-0 d-grid place-content-center w-5 h-5 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-10">
                                            <i className="bi bi-check2"></i>
                                        </span>
                                        <span className="d-block fs-14 clr-primary-10 fw-medium"> Advanced Model (GPT-4)</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex gap-3">
                                        <span
                                            className="flex-shrink-0 d-grid place-content-center w-5 h-5 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-10">
                                            <i className="bi bi-check2"></i>
                                        </span>
                                        <span className="d-block fs-14 clr-primary-10 fw-medium"> 500 Files and Webpages </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex gap-3">
                                        <span
                                            className="flex-shrink-0 d-grid place-content-center w-5 h-5 rounded-circle border border-primary-key border-opacity-3 bg-primary-key bg-opacity-2 clr-primary-10">
                                            <i className="bi bi-check2"></i>
                                        </span>
                                        <span className="d-block fs-14 clr-primary-10 fw-medium"> Unlimited Captured.. </span>
                                        </div>
                                    </li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                            <div className="mt-10 bg-white py-8 py-xl-10 px-5 px-xxl-20 rounded-5">
                                <div className="row gy-4 align-items-center justify-content-between">
                                <div className="col-auto">
                                    <img src="/app/logo.png" alt="logo" className="img-fluid fadeIn_bottom w150" />
                                    <p className="max-text-40 mt-3 animate-text-from-right">Tailored licensing & plans for organizations</p>
                                    <a href="/" target="_blank"
                                    className="link py-3 px-7 bg-grad-4 rounded-1 fs-14 fw-bold text-center clr-white ff-1 d-inline-flex gap-2 align-items-center mt-2 fadeIn_bottom">
                                    <span>Contact Us</span>
                                    <span className="fs-10">
                                        <i className="bi bi-arrow-right"></i>
                                    </span>
                                    </a>
                                </div>
                                <div className="col-auto">
                                    <img src="/main/assets/img/home-5-pricing-bottom.webp" alt="" className="img-fluid fadeIn_bottom" />
                                </div>
                                <div className="col-auto">
                                    <ul className="list gap-3">
                                    <li className="d-flex align-items-center gap-2 fadeIn_bottom">
                                        <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                        <span className="fs-14 fw-semibold">Customized licensing terms</span>
                                    </li>
                                    <li className="d-flex align-items-center gap-2 fadeIn_bottom">
                                        <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                        <span className="fs-14 fw-semibold">Customized templates</span>
                                    </li>
                                    <li className="d-flex align-items-center gap-2 fadeIn_bottom">
                                        <img src="/main/assets/img/list-star.png" alt="star icon image" className="img-fluid" />
                                        <span className="fs-14 fw-semibold">Monetize your templates on Soundful</span>
                                    </li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <img src="/main/assets/img/home-5-pricing-top.png" alt=""
                        className="img-fluid pricing-section-4-top-img position-absolute" />
                    </div>
                </div>
                <img src="/main/assets/img/home-5-left-shape.png" alt=""
                className="img-fluid pricing-section-shape pricing-section-shape-left" />
                <img src="/main/assets/img/home-5-right-shape.png" alt=""
                className="img-fluid pricing-section-shape pricing-section-shape-right" />
            </section>
            <section className="testimonial-section-2 section-space-sm-top section-space-sm-bottom overflow-hidden">
                <div className="container">
                    <div className="testimonial-section-2-wrapper">
                        <div className="row gy-4">
                        <div className="col-xl-5">
                            <div className="row gy-5 justify-content-end">
                            <div className="col-lg-6 col-xl-12">
                                <h3 className="fw-extrabold max-text-24 animate-text-from-right">Our Trust <img
                                    src="/main/assets/img/thumbs.png" alt="" className="img-fluid fadeIn_bottom" /> <span
                                    className="clr-grad-7 d-block animate-text-from-right">Customer Stories</span></h3>
                                <h6 className="fs-18 mt-3 mt-lg-6 animate-text-from-right">Excellent 4.7 out of 5</h6>
                                <a href="/"
                                className="link d-inline-flex justify-content-center align-items-center gap-2 mt-4 mt-lg-6 py-3 px-4 border border-primary-10 bg-primary-10 rounded-1 fs-14 fw-bold text-center clr-white :bg-grad-4 :border-opacity-0 fadeIn_bottom">
                                <span className="d-block"> View more </span>
                                <span className="d-block fs-10">
                                    <i className="bi bi-arrow-right"></i>
                                </span>
                                </a>
                            </div>
                            <div className="col-lg-6 col-xl-9">
                                <div className="bg-white py-8 py-lg-12 py-xl-15 px-6 px-lg-8 px-xl-10 rounded-3">
                                <div className="d-flex flex-wrap align-items-center gap-4">
                                    <div className="w-16 h-16 rounded-circle overflow-hidden fadeIn_bottom">
                                    <img src="/main/assets/img/testimonial-2-avatar-3.webp" alt=""
                                        className="img-fluid object-fit-cover w-100 h-100" />
                                    </div>
                                    <div>
                                    <h5 className="fs-24 fw-bold mb-1 clr-primary-10 animate-text-from-right">Mark Villomas</h5>
                                    <p className="mb-0 fw-medium animate-text-from-right">Designer</p>
                                    </div>
                                </div>
                                <h5 className="h6 fw-bold mt-6 mt-lg-8 mt-xl-10 mb-4 mb-lg-6 clr-primary-10 animate-text-from-right">We
                                    have a great tool!</h5>
                                <p className="mb-0 animate-text-from-right">@maccaw holy shit reflect app design is so good my writing and
                                    introspective ability went up 10x since i got it. was using bear/notion before but the simplicity of
                                    reflect is beautiful ai wirtebot cotbot</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="row gy-4 justify-content-end">
                            <div className="col-xl-10 col-lg-6">
                                <div className="bg-white py-8 py-lg-12 py-xl-15 px-6 px-lg-8 px-xl-10 rounded-3">
                                <div className="d-flex flex-wrap align-items-center gap-4">
                                    <div className="w-16 h-16 rounded-circle overflow-hidden fadeIn_bottom">
                                    <img src="/main/assets/img/testimonial-2-avatar-1.webp" alt=""
                                        className="img-fluid object-fit-cover w-100 h-100" />
                                    </div>
                                    <div>
                                    <h5 className="fs-24 fw-bold mb-1 clr-primary-10 animate-text-from-right">Mark Villomas</h5>
                                    <p className="mb-0 fw-medium animate-text-from-right">Designer</p>
                                    </div>
                                </div>
                                <h5 className="h6 fw-bold mt-6 mt-lg-8 mt-xl-10 mb-4 mb-lg-6 clr-primary-10 reveal-text">Awesome Service
                                    For AI vocal Remover</h5>
                                <p className="mb-0 animate-text-from-right">@maccaw holy shit reflect app design is so good my writing and
                                    introspective ability went up 10x since i got it. was using bear/notion before but the simplicity of
                                    reflect is beautiful</p>
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-6">
                                <div className="bg-white py-8 py-lg-12 py-xl-15 px-6 px-lg-8 px-xl-10 rounded-3">
                                <div className="d-flex flex-wrap align-items-center gap-4">
                                    <div className="w-16 h-16 rounded-circle overflow-hidden fadeIn_bottom">
                                    <img src="/main/assets/img/testimonial-2-avatar-2.webp" alt=""
                                        className="img-fluid object-fit-cover w-100 h-100" />
                                    </div>
                                    <div>
                                    <h5 className="fs-24 fw-bold mb-1 clr-primary-10 animate-text-from-right">Mark Villomas</h5>
                                    <p className="mb-0 fw-medium animate-text-from-right">Designer</p>
                                    </div>
                                </div>
                                <h5 className="h6 fw-bold mt-6 mt-lg-8 mt-xl-10 mb-4 mb-lg-6 clr-primary-10 reveal-text">Awesome Service
                                    For AI vocal Remover</h5>
                                <p className="mb-0 animate-text-from-right">@maccaw holy shit reflect app design is so good my writing and
                                    introspective ability went up 10x since i got it. was using bear/notion before but the simplicity of
                                    reflect is beautiful</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <img src="/main/assets/img/testimonial-2-shade.png" alt=""
                        className="img-fluid testimonial-section-2-el testimonial-section-2-el-shade" />
                        <img src="/main/assets/img/testimonial-2-el-1.webp" alt=""
                        className="img-fluid testimonial-section-2-el testimonial-section-2-el-1" />
                        <img src="/main/assets/img/testimonial-2-el-2.webp" alt=""
                        className="img-fluid testimonial-section-2-el testimonial-section-2-el-2" />
                        <img src="/main/assets/img/testimonial-2-el-3.webp" alt=""
                        className="img-fluid testimonial-section-2-el testimonial-section-2-el-3" />
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;