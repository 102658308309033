import React, { useRef, useState } from "react";
import "../../../User/css/workspace.css";

const Imageuploader = ({ onFileChange }) => {
    const inputRef = useRef();

    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');


    // Handle the change event when a file is selected
    const handleOnChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
            const selectedFilePreview = event.target.files[0];
            onFileChange(selectedFilePreview);


            const reader = new FileReader();
            reader.onload = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(selectedFilePreview);
            console.log("reader", reader)
        }
    };

    const onChooseFile = () => {
        inputRef.current.click();
    };

   

    return (
        <div className="flex justify-center align-center mt-10">
            {/* Hidden file input element */}
            <input
                type="file"
                name="picture"
                ref={inputRef}
                onChange={handleOnChange}
                style={{ display: "none" }}
            />

            {/* Button to trigger the file input dialog */}
            <button className="file-btn" onClick={onChooseFile}>
                {!previewUrl ?
                    <span class="material-symbols-rounded ">Upload </span>
                    :
                    <div className="image-preview">
                        {previewUrl && <img  onClick={onChooseFile}
                            className="image-preview--main" src={previewUrl} alt="Preview" />}

                    </div>}
            </button>

        
        </div>
    );
};

export default Imageuploader;