import TitleBar from "../../Components/User/TitleBar";
import SingleRecording from "./Includes/SingleRecording";

const UserRecordings = () => {
    return (
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
                <TitleBar title={"Recordings"} description="View your previous meetings and transcripts">
                    <button className="btn info-btn btn-sm"><i className="fa-light fa-arrow-up-from-bracket"></i> Upload</button>
                    <button className="btn info-btn btn-sm"><i className="fa-light fa-file-import"></i> Import</button>
                </TitleBar>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-fluid">
                        <div className="row g-5 gx-xl-10 mb-5 mb-xl-10 align-end">
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label className="form-label">Owned By</label>
                                    <select className="form-control form-control-solid">
                                        <option selected value={'me'}>Owned by me</option>
                                        <option value={'any'}>Any Team Member</option>
                                        <option value={'Member1'}>By Member 1</option>
                                        <option value={'Member2'}>By Member 2</option>
                                        <option value={'Member3'}>By Member 3</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <label className="form-label">Tags</label>
                                <input className="form-control form-control-solid" placeholder="Tags" />
                            </div>
                            <div className="col-md-2">
                                <label className="form-label">Keywords</label>
                                <input className="form-control form-control-solid" placeholder="Keywords" />
                            </div>
                            <div className="col-md-2">
                                <label className="form-label">Language</label>
                                <select className="form-control form-control-solid">
                                    <option selected value={'eng'}>English</option>
                                    <option value={'fr'}>French</option>
                                    <option value={'sp'}>Spanish</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <label className="form-label">Created Date</label>
                                <input className="form-control form-control-solid" type="date" />
                            </div>
                            <div className="col-md-2">
                                <button className="btn info-btn w-100">Filter</button>
                            </div>
                        </div>
                        <div className="row g-5 gx-xl-10 mb-5 mb-xl-10">
                            <SingleRecording />
                            <SingleRecording />
                            <SingleRecording />
                            <SingleRecording />
                            <SingleRecording />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserRecordings;