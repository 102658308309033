import React, { useState } from 'react';

function TeamSizeSelector({ onButtonChange }) {
    const [activeButton, setActiveButton] = useState(null);
    const [buttonVal, setButtonVal] = useState("")
    const handleButtonClick = (value, buttonId) => {
        setButtonVal(value)
        setActiveButton(buttonId);
        onButtonChange(value);

    };

    const buttonLabels = ["Just me", "2-5", "6-10", "11-25", "25-50", "51-200", "201-500", "500+"];

    return (
        <>
            {buttonLabels.map((label, index) => (
                <button
                    key={index}
                    className={`btn btn-sm outline-btn ${activeButton === index ? 'active-btn active' : ''}`}
                    onClick={() => handleButtonClick(label, index)}
                >
                    {label}
                </button>
            ))}
        </>
    );
}

export default TeamSizeSelector;
