import { Outlet, useLocation } from "react-router-dom";
import AppHeader from "./Layout/AppHeader";
import AppSidebar from "./Layout/AppSidebar";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import Helpers from "../Config/Helpers";
import './css/app.css';

const UserLayout = () => {

    const location = useLocation();

    useEffect(() => {
        Helpers.toggleCSS();
    }, [location.pathname]);

    return (
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
            <div><Toaster position="top-right" /></div>
            <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                <AppHeader />
                <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                    <AppSidebar />
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default UserLayout;