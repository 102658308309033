import { Link, useNavigate } from "react-router-dom";
import Helpers from "../../../Config/Helpers";
import { useGoogleLogin } from "@react-oauth/google";
import toast from "react-hot-toast";
import axios from "axios";
import { useState } from "react";
import BtnSpinner from "../../../Components/BtnSpinner";

const Login = () => {

    const [googleLoading, setGoogleLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
 
    const loginWithGoogle = useGoogleLogin({
        scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/meetings',
        onSuccess: async (tokenResponse) => {
            setIsLoading(true);
            setGoogleLoading(true);
            try {
                let data = {token: tokenResponse.access_token}
                await axios.post(`${Helpers.apiUrl}auth/register-google`, data).then(response => {
                    Helpers.setItem("user", response.data.user, true);
                    Helpers.setItem("token", response.data.token);
                    toast.success(response.data.message, Helpers.toastOptions);
                    setTimeout(() => {
                        window.location.href = "/user/dashboard";
                    }, 1000);
                }).catch(error => {
                    toast.error(error.response.data.message, Helpers.toastOptions);
                    setIsLoading(false);
                    setGoogleLoading(false);
                });
            } catch (error) {
                toast.error(error, Helpers.toastOptions);
                setIsLoading(false);
                setGoogleLoading(false);
            }
        },
        onError: error => {
            toast.error(error, Helpers.toastOptions);
        }
    });

    // const loginWithGoogle = () => {
    //     toast.success("You are registered successfully", Helpers.toastOptions);
    // }

    return (
        <section className="pricing-section-4 section-space-sm-top section-space-sm-bottom position-relative" style={{ paddingTop: 150 }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-3">
                        <div className="pricing-section-4-wrapper rounded-4 surface-bright section-space-top section-space-bottom position-relative overflow-hidden px-5 px-lg-10 px-xxl-20">
                            <div className="row gy-4 align-items-center justify-content-between">
                                <div className="col-12">
                                    <h3 className="fw-extrabold mb-0 animate-line-3d">Let's Create<br/><span className="clr-grad-7">Your Account</span></h3>
                                </div>
                            </div>
                            <div className="mt-10">
                                <div className="row justify-content-center">
                                    <button disabled={isLoading} onClick={loginWithGoogle} className="btn-auth">{googleLoading ? <BtnSpinner /> : <img src="/app/google.png" className="auth-icon" alt="" />} {googleLoading ? 'Please wait...' : 'Continue with Google'}</button>
                                    <button disabled={isLoading} className="btn-auth"><img src="/app/microsoft.png" className="auth-icon" alt="" /> Continue with Microsoft</button>
                                    <button disabled={isLoading} className="btn-auth"><img src="/app/envelope.png" className="auth-icon" alt="" /> Continue with Email</button>
                                </div>
                            </div>
                            <div className="text-center mt-10">
                                <small>By using { Helpers.appName } you agree to the <Link to={'/terms-conditions'}>Terms of Service</Link> and <Link to={'/privacy-policy'}>Privacy Policy</Link></small>
                            </div>
                            <img src="/main/assets/img/home-5-pricing-top.png" alt=""
                            className="img-fluid pricing-section-4-top-img position-absolute" />
                        </div>
                    </div>
                </div>
            </div>
            <img src="/main/assets/img/home-5-left-shape.png" alt=""
            className="img-fluid pricing-section-shape pricing-section-shape-left" />
            <img src="/main/assets/img/home-5-right-shape.png" alt=""
            className="img-fluid pricing-section-shape pricing-section-shape-right" />
        </section>
    );
}

export default Login;