import { User } from "react-feather";
import Helpers from "../../Config/Helpers";
import { Link } from "react-router-dom";
import RecordingCheck from "../RecordingCheck";
import { useState } from "react";

const MeetingOverview = ({ meeting }) => {

    const meeting_details = JSON.parse(meeting.details);

    const meetingTime = Helpers.getTimeDetails(meeting_details.start.dateTime, meeting_details.end.dateTime);
    const [onRecord, setOnRecord] = useState(meeting.auto_record);

    const updateRecordingStatus = e => {
        console.log(e.target.checked);
        setOnRecord(e.target.checked);
    }

    return (
        <div className="d-flex align-items-center mb-6">
            <span data-kt-element="bullet" className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"></span>
            <div className="flex-grow-1 me-5">
                <div className="text-gray-800 fw-semibold fs-2">{ meetingTime.start } - { meetingTime.end } 
                <span className="text-gray-500 fw-semibold fs-7">{ meetingTime.type }</span></div>
                <div className="text-gray-700 fw-semibold fs-6">{ meeting_details.summary ? meeting_details.summary : '(No title)' }</div>
                <div className="text-gray-500 fw-semibold mt-1">
                    <User size={14} /> <a href="#!" className="text-primary opacity-75-hover fw-semibold">{ meeting_details.attendees.length } attendees</a>
                </div>
            </div>
            <RecordingCheck id={`${meetingTime.start}-${meeting.id}`} onRecord={onRecord} onChange={updateRecordingStatus} />
            <Link to={'/'} target="_blank" className="btn btn-sm info-btn ml5" rel="noreferrer">View Details</Link>
            {(meeting_details.hangoutLink && Helpers.isFutureDate(meeting_details.end.dateTime)) && <a href={meeting_details.hangoutLink} target="_blank" className="btn btn-sm light-bg ml5" rel="noreferrer"><img style={{ height: 17 }} src="/app/google-meet.png" alt="" /> Join meeting</a>}
        </div>
    );
}

export default MeetingOverview;