import toast from "react-hot-toast";
import CalendarDate from "../Components/Dashboard/CalendarDate";
import MeetingOverview from "../Components/Dashboard/MeetingOverview";
import { useEffect, useState } from "react";
import Helpers from '../Config/Helpers'
import axios from "axios";
import LineLoader from "../Components/LineLoader";

const UserDashboard = () => {

    const [googleMeetings, setGoogleMeetings] = useState([]);
    const meetingDates = Helpers.getDates();
    const [currentActiveDate, setCurrentActiveDate] = useState(Helpers.getDates().find(meeting => meeting.is_active));
    const [loadingMeetings, setLoadingMeetings] = useState(false);

    const getGoogleMeetings = () => {
        const googleToken = Helpers.authUser.login_token;
        if(googleToken){
            setLoadingMeetings(true);
            const url = `https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin=${encodeURIComponent(currentActiveDate.day_start)}&timeMax=${encodeURIComponent(currentActiveDate.day_end)}&singleEvents=true&orderBy=startTime`;
            axios.get(url, {
                headers: {
                    "Authorization": `Bearer ${googleToken}`,
                }
            }).then(response => {
                setLoadingMeetings(false);
                // if(response.data.items.length > 3){
                //     setGoogleMeetings(response.data.items.slice(0, 3));
                // }else{
                //     setGoogleMeetings(response.data.items);
                // }
                const data = { meetings: response.data.items, date: currentActiveDate.full_date };
                axios.post(`${Helpers.apiUrl}meeting/save-google-meetings`, data, Helpers.authHeaders).then(response => {
                    setGoogleMeetings(response.data.meetings);
                    console.log(`Meetings synced for ${response.data.date}`);
                }).catch(error => {
                    console.log("Error saving meetings", error);
                });
            }).catch(error => {
                console.log(error);
                axios.get(`${Helpers.apiUrl}meeting/get-google-meetings/${ currentActiveDate.full_date }`, Helpers.authHeaders).then(response => {
                    let meets = response.data.meetings;
                    let meeting_items = [];
                    meets.forEach(meet => {
                        meeting_items.push(meet.details);
                    });
                    setGoogleMeetings(meeting_items);
                    setLoadingMeetings(false);
                }).catch(error => {
                    console.log("Error fetching meetings from nuance server", error);
                    setLoadingMeetings(false);
                });
                setLoadingMeetings(false);
            });
        }
    }

    useEffect(() => {
        getGoogleMeetings();
    }, [currentActiveDate]);

    return (
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                    <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">Multipurpose</h1>
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                <li className="breadcrumb-item text-muted">
                                    <a href="index.html" className="text-muted text-hover-primary">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="bullet bg-gray-500 w-5px h-2px"></span>
                                </li>
                                <li className="breadcrumb-item text-muted">Dashboards</li>
                            </ul>		
                        </div>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <button onClick={getGoogleMeetings} className="btn btn-sm fw-bold btn-secondary">Rollover</button>
                            <a href="/" className="btn btn-sm fw-bold btn-primary">Add Target</a>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-fluid">
                        <div className="row g-5 gx-xl-10 mb-5 mb-xl-10">
                            {/* <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                                <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-100 mb-1 mb-xl-10 white" style={{backgroundColor: '#F1416C', backgroundImage:"url('/assets/images/vector-1.png')"}}>
                                    <div className="card-header pt-5">
                                        <div className="card-title d-flex flex-column">
                                            <span className="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">69</span>
                                            <span className="text-white opacity-75 pt-1 fw-semibold fs-6">Active Projects</span>
                                        </div>
                                    </div>
                                    <div className="card-body d-flex align-items-end pt-0">
                                        <div className="d-flex align-items-center flex-column mt-3 w-100">
                                            <div className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2">
                                                <span>43 Pending</span>
                                                <span>72%</span>
                                            </div>
                                            <div className="h-8px mx-3 w-100 bg-white bg-opacity-50 rounded">
                                                <div className="bg-white rounded h-8px" role="progressbar" style={{ width: '72%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-12 col-xl-12 col-xxl-8 mb-5 mb-xl-0">
                                <div className="card h-md-100">
                                    <div className="card-header border-0 pt-5">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bold text-gray-900">What’s up Today</span>
                                            <span className="text-muted mt-1 fw-semibold fs-7">Total {googleMeetings.length} today</span>
                                        </h3>
                                        <div className="card-toolbar">
                                            <a href="#!" className="btn btn-sm light-bg">View All Meeting</a>
                                        </div>
                                    </div>
                                    <div className="card-body pt-7 px-0">
                                        <ul className="nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5">
                                            {meetingDates.map((meeting_date, index) => <CalendarDate key={index} date={meeting_date.date} day={meeting_date.short_day} isActive={meeting_date.date === currentActiveDate.date} onClick={() => setCurrentActiveDate(meeting_date)} />)}
                                        </ul>
                                        <div className="mb-2 px-9">
                                            {loadingMeetings && <LineLoader />}
                                            {googleMeetings.length === 0 && <p>No meetings scheduled for { currentActiveDate.full_date }</p>}
                                            {googleMeetings.map((meeting, index) => <MeetingOverview meeting={meeting} key={index} />)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserDashboard;