import SidebarMenuItem from "./MenuItem";

const AppSidebar = () => {
    return (
        <div className="app-sidebar flex-column" id="kt_app_sidebar">
            <div className="app-sidebar-logo px-6" id="kt_app_sidebar_logo">
                <a href="index.html">
                    <img alt="Logo" src="/app/logo-white.png" className="h-25px app-sidebar-logo-default" />
                    <img alt="Logo" src="/app/favicon-white.png" className="h-20px app-sidebar-logo-minimize" />
                </a>
            </div>
            <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
                <div id="kt_app_sidebar_menu_wrapper" className="app-sidebar-wrapper">
                    <div className="scroll-y my-5 mx-3" id="kt_app_sidebar_menu_scroll">
                        <div className="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6" id="#kt_app_sidebar_menu">
                            <SidebarMenuItem link="/user/dashboard" icon={"fa-house"} text={"Dashboard"} />
                            <SidebarMenuItem link="/user/recordings" icon={"fa-camera-movie"} text={"Recordings"} />
                            <SidebarMenuItem link="/user/meetings" icon={"fa-calendar"} text={"Meetings"} />
                            <SidebarMenuItem link="/user/videos" icon={"fa-video"} text={"Videos"} />
                            <SidebarMenuItem link="/user/workspaces" icon={"fa-briefcase"} text={"Workspaces"} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6" id="kt_app_sidebar_footer">
                <a href="/" className="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100">
                    <i className="fa-duotone fa-arrow-right-from-bracket"></i>
                    <span className="btn-label ml10">Sign Out</span>
                </a>
            </div>
        </div>
    );
}

export default AppSidebar;