import Card from "../../Components/Card";
import MainApp from "../../Components/MainApp";
import FullRow from "../../Components/FullRow";
import TitleBar from "../../Components/User/TitleBar";
import AppContainer from "./Includes/AppContainer";
import React,{ useState } from "react";
import Imageuploader from "./Includes/Imageuploader";
import TeamSizeSelector from "./Includes/TeamSizeSelect";
import Helpers from "../../Config/Helpers";
import axios from "axios";
const WorkSpaces = () => {
    const [allWorkSpace, getAllWorkSpace] = useState("")
    const [workspaceForm, setWorkspaceForm] = useState(false);
    const [data, setData] = useState({
        workspace_name: '',
        description: '',
        // picture: "",
        region: "",
    });

    const handleFileChange = (picture) => {
        setData(prevFormData => ({ ...prevFormData, picture }));
    };

    const handleButtonChange = (team_size) => {
        setData(prevFormData => ({ ...prevFormData, team_size }));
    };

    const handleChange = async (event) => {
        const { name, value, files } = event.target;

        setData(prevFormData => ({
            ...prevFormData,
            [name]: files ? files[0] : value
        }));

        const formData = new FormData();
        Object.keys(data).forEach(key => {
            if (data[key] !== '') {
                formData.append(key, data[key]);
            }
        });

    };

    const handleForm = async () => {

        const formData = new FormData();
        Object.keys(data).forEach(key => {
            if (data[key] !== '') {
                formData.append(key, data[key]);
            }
        });

        console.log("form data", formData)
        await axios.post(`${Helpers.apiUrl}workspace/create`, formData, Helpers.authFileHeaders)

    }

 

    React.useEffect(()=>{
    const getAllData =async ( )=>{
        const res =await axios.get(`${Helpers.apiUrl}workspace/all`, Helpers.authHeaders)
        console.log("result===data", res.data)
        getAllWorkSpace(res?.data)
        }
        getAllData()
    },[])



    return (
        <MainApp>
            <TitleBar title={"Workspaces"} description="Effortlessly Organize Your Workspace, Boost Your Efficiency">
                {!workspaceForm && <button onClick={() => setWorkspaceForm(true)} className="btn info-btn btn-sm"><i className="fa-light fa-add"></i> Add Workspace</button>}
                {workspaceForm && <button onClick={() => setWorkspaceForm(false)} className="btn danger-btn btn-sm"><i className="fa-light fa-xmark"></i> Cancel</button>}
            </TitleBar>
            <AppContainer>
                {!workspaceForm && <div className="row g-5 gx-xl-10 mb-5 mb-xl-10">
                    <div className="col-4">
                    { allWorkSpace &&  allWorkSpace.map((space, idx)=>
                        <Card key={idx}>
                            <FullRow className="mt-3 mb-3 text-center">
                                <img src={Helpers.serverImage(space.picture)} className="img-workspace mb-3" alt="" />
                                <h3>{space.workspace_name}</h3>
                                <small className="text-muted">{space.members.length} | {space.region}</small>
                            </FullRow>
                        </Card>
                        )}
                    </div>
                </div>}
                {workspaceForm && <div className="row g-5 gx-xl-10 mb-5 mb-xl-10">
                    <div className="col-12">
                        <Card>
                            <FullRow className="mb-5">
                                <h1>Create Your Workspace</h1>
                            </FullRow>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <label className="mb-2">Define the name for your Workspace <small>(You can change it later in workspace settings)</small></label>
                                    <input name="workspace_name"
                                        onChange={handleChange}
                                        value={data.workspace_name} className="form-control form-control-solid" placeholder="Workspace Name" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 mb-3">
                                    <label className="mb-2">Your Region  </label>
                                    <input name="region" value={data.region} onChange={handleChange} className="form-control form-control-solid" placeholder="Enter you Reigon" />

                                </div>
                                <div className="col-6 mb-3">
                                    <label className="mb-2">Your team size  </label>

                                    <div className="flex justify-between">
                                        <TeamSizeSelector onButtonChange={handleButtonChange} />
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-6 mb-3">
                                    <label className="mb-2">Upload Image </label>

                                    <Imageuploader onFileChange={handleFileChange} />
                                </div>
                                <div className="col-6 mb-3 flex  flex-col">
                                    <label className="mb-2 ">Select Image </label>
                                    <div className="mt-10 flex justify-between">
                                        <img className="image-showcase mb-3 " src="/app/work1.png" alt="img1" />
                                        <img className="image-showcase mb-3 " src="/app/work2.png" alt="img1" />
                                        <img className="image-showcase mb-3 " src="/app/work3.png" alt="img1" />
                                        <img className="image-showcase mb-3 " src="/app/work4.png" alt="img1" />
                                        <img className="image-showcase mb-3 " src="/app/work5.png" alt="img1" />

                                    </div>
                                    <div className="flex justify-between">
                                        <img className="image-showcase mb-3 " src="/app/work6.png" alt="img1" />
                                        <img className="image-showcase mb-3 " src="/app/work7.png" alt="img1" />
                                        <img className="image-showcase mb-3 " src="/app/work8.png" alt="img1" />
                                        <img className="image-showcase mb-3 " src="/app/work9.png" alt="img1" />
                                        <img className="image-showcase mb-3 " src="/app/work10.png" alt="img1" />

                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-3">

                                    <label className="mb-2">Description About your workspace  </label>
                                    {/* <input className="form-control form-control-solid" placeholder="Enter Description here" /> */}
                                    <textarea name="description"
                                        value={data.description}
                                        onChange={handleChange} rows="5" cols="30" className="textarea form-control form-control-solid" placeholder="Enter Description here" />

                                </div>
                            </div>

                            <FullRow className="mt-2">
                                <button onClick={handleForm} className="btn info-btn">Continue</button>
                            </FullRow>
                        </Card>
                    </div>
                </div>}
            </AppContainer>
        </MainApp>
    );
}

export default WorkSpaces;