const CalendarDate = ({ isActive = false, day, date, onClick }) => {
    return (
        <li className="nav-item p-0 ms-0 pointer" onClick={onClick}>
            <div className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 active-btn ${ isActive ? 'active' : '' }`}>
                <span className="fs-7 fw-semibold">{ day }</span>
                <span className="fs-6 fw-bold">{ date }</span>
            </div>
        </li>
    );
}

export default CalendarDate;