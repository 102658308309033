import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const hideDropdown = () => {
    setIsOpen(false);
  }

  return (
    <>
      <button className="btn light-bg btn-sm" onClick={toggleDropdown}><i className="fa-light fa-bars"></i></button>
      {isOpen && (
        <div className="card dropdown" ref={dropdownRef} onMouseLeave={hideDropdown} style={{ zIndex: 999 }}>
          <Link className='dropdown-link' to={'/'}><i className="fa-light fa-arrow-down-to-line"></i> <span className='ml5'>Download Video</span></Link>
          <Link className='dropdown-link' to={'/'}><i className="fa-light fa-heart"></i> <span className='ml5'>Add to Favourites</span></Link>
          <Link className='dropdown-link' to={'/'}><i className="fa-light fa-arrow-down-to-line"></i> <span className='ml5'>Download Transcript</span></Link>
          <Link className='dropdown-link danger' to={'/'}><i className="fa-light fa-trash"></i> <span className='ml5'>Delete Meeting</span></Link>
        </div>
      )}
    </>
  );
};

export default Dropdown;
