const MainApp = ({ children }) => {
    return (
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
                {children}
            </div>
        </div>
    )
}

export default MainApp;