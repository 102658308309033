import { useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Helpers from "../Config/Helpers";
import { Toaster } from "react-hot-toast";
import './css/main.css';

const MainLayout = () => {

    const location = useLocation();

    useEffect(() => {
        Helpers.loadScript("main/assets/js/plugins.js")
            .then(() => Helpers.loadScript("main/assets/js/app.js"))
            .catch(error => console.error("Script loading failed: ", error));
    }, []);

    useEffect(() => {
        Helpers.toggleCSS();
    }, [location.pathname]);

    return (
        <>
            <div><Toaster position="top-right" /></div>
            <header className="header header--fixed py-lg-6 header--5 backblur">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <nav className="menu d-lg-flex justify-content-lg-between align-items-lg-center py-3 py-lg-0">
                            <div className="d-flex align-items-center justify-content-between">
                            <Link to="/" className="logo link d-inline-flex align-items-center flex-shrink-0">
                                <img src="/app/logo.png" alt="logo" className="img-fluid object-fit-contain w150" />
                            </Link>
                            <button
                                className="menu-toggle w-8 h-8 p-0 border-0 lh-1 bg-primary-50 clr-neutral-100 :clr-neutral-100 transition :bg-primary-300 rounded flex-shrink-0 d-lg-none order-sm-3 fs-24">
                                <i className="bi bi-list"></i>
                            </button>
                            </div>
                            <div
                            className="menu-nav d-flex align-items-lg-center flex-column flex-lg-row flex-grow-1 gap-4 pb-4 pb-lg-0 rounded">
                            <ul className="list list-lg-row mx-lg-auto rounded-3">
                                <li className="menu-list">
                                <a href="/" className="link menu-link has-sub"> Solutions </a>
                                <ul className="list menu-sub">
                                    <li className="menu-sub-list">
                                    <a href="/" className="link menu-sub-link gap-3">
                                        <span className="d-none d-lg-inline-block">
                                        <img src="/main/assets/img/ai-video-icon.png" alt="" className="img-fluid" />
                                        </span> Business </a>
                                    </li>
                                    <li className="menu-sub-list">
                                    <a href="/" className="link menu-sub-link gap-3">
                                        <span className="d-none d-lg-inline-block">
                                        <img src="/main/assets/img/ai-image-icon.png" alt="" className="img-fluid" />
                                        </span> Sales </a>
                                    </li>
                                    <li className="menu-sub-list">
                                    <a href="/" className="link menu-sub-link gap-3">
                                        <span className="d-none d-lg-inline-block">
                                        <img src="/main/assets/img/ai-saas-icon-2.png" alt="" className="img-fluid" />
                                        </span> Education </a>
                                    </li>
                                    <li className="menu-sub-list">
                                    <a href="/" className="link menu-sub-link gap-3">
                                        <span className="d-none d-lg-inline-block">
                                        <img src="/main/assets/img/ai-voice-icon.png" alt="" className="img-fluid" />
                                        </span> Media </a>
                                    </li>
                                </ul>
                                </li>
                                <li className="menu-list">
                                <a href="/" className="link menu-link"> Pricing </a>
                                </li>
                                <li className="menu-list">
                                <a href="/" className="link menu-link has-sub"> Who uses Nuance? </a>
                                <ul className="list menu-sub">
                                    <li className="menu-sub-list">
                                    <a href="/" className="link menu-sub-link"> Sales </a>
                                    </li>
                                    <li className="menu-sub-list">
                                    <a href="/" className="link menu-sub-link"> Product & User Research </a>
                                    </li>
                                    <li className="menu-sub-list">
                                        <a href="/" className="link menu-sub-link"> Hiring & Recruiting </a>
                                    </li>
                                    <li className="menu-sub-list">
                                        <a href="/" className="link menu-sub-link"> Remote Teams </a>
                                    </li>
                                </ul>
                                </li>
                                <li className="menu-list">
                                    <a href="/" className="link menu-link has-sub"> About </a>
                                    <ul className="list menu-sub">
                                        <li className="menu-sub-list">
                                            <a href="/" className="link menu-sub-link"> About Us </a>
                                        </li>
                                        <li className="menu-sub-list">
                                            <Link to="/privacy-policy" className="link menu-sub-link"> Privacy Policy </Link>
                                        </li>
                                        <li className="menu-sub-list">
                                            <Link to="/terms-conditions" className="link menu-sub-link"> Terms & Conditions </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="list list-row gap-xl-6 gap-lg-4 bg-transparent align-items-center">
                                <li className="menu-list mx-4 mx-lg-0">
                                    <Link to="/auth/login" className="link d-inline-flex justify-content-center py-3 px-xl-5 px-4 fs-14 fw-bold text-center clr-primary-10 py-2 px-4 rounded-1 h5-btn-border-left bg-white-12">
                                        Log In
                                    </Link>
                                </li>
                                <li className="menu-list menu-list-border-none mx-4 mx-lg-0">
                                <a href="/"
                                    className="link py-3 px-xl-5 px-4 bg-primary-10 :bg-primary-40 rounded-1 fs-14 fw-bold text-center clr-white">
                                    Start for Free </a>
                                </li>
                            </ul>
                            </div>
                        </nav>
                        </div>
                    </div>
                </div>
            </header>
            <Outlet />
            <footer className="footer-5 section-space-sm-top pb-9">
                <div className="container">
                <div
                    className="footer-5-wrapper section-space-top bg-black rounded-5 px-5 px-sm-10 px-xl-12 px-xxl-20 pb-8 pb-xl-10 pb-xxl-12">
                    <div className="row gy-5 justify-content-xl-between">
                        <div className="col-lg-12 col-xl-4">
                            <a href="/">
                            <img src="/app/logo-duo.png" alt="logo" className="img-fluid w150" />
                            </a>
                            <p className="clr-surface mt-8">Subscribe to our weekly newsletter</p>
                            <form>
                            <div className="newsletter-1 position-relative z-1 overflow-hidden rounded-1">
                                <input type="email" name="#0"
                                className="form-control newsletter-1__input clr-white inverse-surface border-0 footer-5-newsletter-input rounded-1"
                                placeholder="join the community" />
                            </div>
                            <button type="submit"
                                className="link py-3 px-5 bg-grad-4 rounded-1 fs-14 fw-bold text-center clr-white ff-1 d-inline-flex gap-2 align-items-center mt-2 border-0 mt-5">
                                <span>Subscribe</span>
                                <i className="bi bi-arrow-up-right"></i>
                            </button>
                            </form>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-2">
                            <h5 className="inverse-on-surface fs-18 fw-bold mb-7">Resources</h5>
                            <ul className="list gap-4">
                            <li>
                                <a href="/" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Blog </a>
                            </li>
                            <li>
                                <a href="/" className="link d-inline-block clr-neutral-80 :clr-primary-key"> About </a>
                            </li>
                            <li>
                                <a href="/" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Request API Access
                                </a>
                            </li>
                            <li>
                                <a href="/" className="link d-inline-block clr-neutral-80 :clr-primary-key"> FAQ </a>
                            </li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-2">
                            <h5 className="inverse-on-surface fs-18 fw-bold mb-7">Quick Menu</h5>
                            <ul className="list gap-4">
                            <li>
                                <a href="/" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Features </a>
                            </li>
                            <li>
                                <a href="/" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Login </a>
                            </li>
                            <li>
                                <a href="/" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Pricing </a>
                            </li>
                            <li>
                                <a href="/" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Register </a>
                            </li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-2">
                            <h5 className="inverse-on-surface fs-18 fw-bold mb-7">Resources</h5>
                            <ul className="list gap-4">
                            <li>
                                <a href="/" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Use Cases </a>
                            </li>
                            <li>
                                <Link to="/privacy-policy" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Privacy &
                                Policy </Link>
                            </li>
                            <li>
                                <Link to="/terms-conditions" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Terms &
                                Condition </Link>
                            </li>
                            <li>
                                <a href="/" className="link d-inline-block clr-neutral-80 :clr-primary-key"> Features </a>
                            </li>
                            </ul>
                        </div>
                        </div>
                        <div className="row gy-4 mt-12 align-items-center">
                        <div className="col-lg-8">
                            <p className="clr-neutral-80 mb-0 text-lg-start text-center">© All rights reserved <a
                                href="/" className="clr-grad-7">Nuance</a></p>
                        </div>
                        <div className="col-lg-4">
                            <ul className="list list-row flex-wrap gap-3 justify-content-lg-end justify-content-center">
                            <li>
                                <a href="/"
                                className="w-11 h-11 inverse-surface d-grid place-content-center rounded-circle clr-white :bg-grad-4">
                                <i className="bi bi-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="/"
                                className="w-11 h-11 inverse-surface d-grid place-content-center rounded-circle clr-white :bg-grad-4">
                                <i className="bi bi-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="/"
                                className="w-11 h-11 inverse-surface d-grid place-content-center rounded-circle clr-white :bg-grad-4">
                                <i className="bi bi-dribbble"></i>
                                </a>
                            </li>
                            <li>
                                <a href="/"
                                className="w-11 h-11 inverse-surface d-grid place-content-center rounded-circle clr-white :bg-grad-4">
                                <i className="bi bi-behance"></i>
                                </a>
                            </li>
                            </ul>
                        </div>
                    </div>
                    <img src="/main/assets/img/footer-5-wrapper-digit.png" alt=""
                    className="img-fluid footer-5-wrapper-shape footer-5-wrapper-shape-digit" />
                    <img src="/main/assets/img/footer-5-wrapper-left.png" alt=""
                    className="img-fluid footer-5-wrapper-shape footer-5-wrapper-shape-left" />
                    <img src="/main/assets/img/footer-5-wrapper-right.png" alt=""
                    className="img-fluid footer-5-wrapper-shape footer-5-wrapper-shape-right" />
                </div>
                </div>
                <img src="/main/assets/img/footer-5-left.png" alt="" className="img-fluid footer-5-shape footer-5-shape-left" />
                <img src="/main/assets/img/footer-5-right.png" alt="" className="img-fluid footer-5-shape footer-5-shape-right" />
            </footer>
        </>
    )
}

export default MainLayout;