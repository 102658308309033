const AppContainer = ({ children }) => {
    return (
        <div id="kt_app_content" className="app-content flex-column-fluid">
            <div id="kt_app_content_container" className="app-container container-fluid">
                {children}
            </div>
        </div>
    );
}

export default AppContainer;