import { BrowserRouter, Route, Routes } from 'react-router-dom';
import UserLayout from './User/Layout';
import UserDashboard from './User/Dashboard';
import MainLayout from './Main/Layout';
import Home from './Main/Screens/Home';
import PrivacyPolicy from './Main/Screens/PrivacyPolicy';
import TermsConditions from './Main/Screens/TermsConditions';
import Login from './Main/Screens/Auth/Login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import UserRecordings from './User/Screens/Recordings';
import RecordingDetails from './User/Screens/RecordingDetails';
import WorkSpaces from './User/Screens/Workspaces';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<MainLayout />}>
          <Route path='/' element={<Home />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-conditions' element={<TermsConditions />} />
            <Route path='/auth/login' element={
              <GoogleOAuthProvider clientId='682482381879-83d027m7t4snb5m1mbe5dh1prf1jm971.apps.googleusercontent.com'>
                <Login />
              </GoogleOAuthProvider>
            } />
        </Route>
        <Route path='/user' element={<UserLayout />}>
          <Route path='/user/dashboard' element={<UserDashboard />} />
          <Route path='/user/recordings' element={<UserRecordings />} />
          <Route path='/user/recording/:recording_id' element={<RecordingDetails />} />
          <Route path='/user/workspaces' element={<WorkSpaces />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
